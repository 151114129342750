<template>
  <div class="demo1">
    <el-radio-group v-model="tabPosition" style="margin-bottom: 30px">
      <el-radio-button label="left">left</el-radio-button>
    </el-radio-group>
    <div class="demo2">
      <el-tabs :tab-position="tabPosition" style="height: 550px">
        <el-tab-pane>
          <span slot="label"
            >{{ list[0].art_title }}<i class="el-icon-arrow-right"></i>
          </span>
          <div class="d1">
            <h2>{{ list[0].art_title }}</h2>
            <p class="article1" v-html="html"></p>
          </div>
        </el-tab-pane>
        <el-tab-pane>
          <span slot="label"
            >{{ list[1].art_title }}<i class="el-icon-arrow-right"></i>
          </span>
          <div class="d1">
            <h2>{{ list[1].art_title }}</h2>
            <p class="article1" v-html="hh"></p>
          </div>
        </el-tab-pane>
        <!-- <el-tab-pane>
          <span slot="label"
            >{{ list[2].art_title }}<i class="el-icon-arrow-right"></i>
          </span>
          <div class="d1">
            <h2>{{ list[2].art_title }}</h2>
            <p class="article1" v-html="hc"></p>
          </div>
        </el-tab-pane> -->
      </el-tabs>
    </div>
  </div>
</template>
<script>
  import { getArticleConent } from '@/api/article'
  export default {
    data() {
      return {
        list: [],
        id: '',
        title: '',
        intr: '',
        image: '',
        sort: '',
        is_del: '',
        cs: '11',
        html: '',
        hh: '',
        hc: '',
        content: '',
        tabPosition: 'left'
      };
    },
    created() {
      this.id = this.$route.query.id;
      console.log(this.id);
      this.getList()
    },
    methods: {
      async getList() {
          console.log(this.id)
        const { data: res } = await getArticleConent(this.id );
        this.list = res.result.list;
        console.log(this.list);
        this.html = res.result.list[0].content;
        this.hh = res.result.list[1].content;
        // this.hc = res.result.list[2].content;
    
      }
    },

  }
</script>

<style lang="less" >
.demo1 {
  width: 1200px;
  height: auto;
  margin: 0 auto;
}

.d1 {
  text-align: left;
}

.d1 h2 {
  font-size: 32px;
  color: #282828;
  margin-top: 0px;
}

.el-tabs--left .el-tabs__item.is-left {
  text-align: center;
  font-size: 16px;
  margin-bottom: 25px;
  margin-right: 20px;
  border-bottom: 1px solid #d3d3d3;
}

.article1 {
  padding: 25px 36px;
  border: 1px solid #ccc;
  margin-bottom: 15px;
}

//去除灰线条
.el-tabs__nav-wrap::after {
  position: static !important;
}

.el-tabs__active-bar is-left {
  height: 0px;
  transform: translateY(0px);
}

//去掉蓝色下拉
.el-tabs__active-bar {
  background-color: transparent;
}

.el-icon-arrow-right {
  padding-left: 20px;
  margin-top: 12px;
  float: right;
}

//左边文字样式
.el-tabs__item:hover {
  color: #fff;
  cursor: pointer;
}

//点击样式
.el-tabs__item.is-active {
  color: #ccc;
  background-color: #ff4400;
  height: 60px;
  width: 200px;
}
</style>