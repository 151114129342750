import request from '@/libs/request'

/**
 * 获取文章分类
 */

export function getArticleList() {

    return request({

        url: '/article/article',

        method: 'get',

    })
}


/**
 * 获取文章内容
 */

export function getArticleConent(id) {

    return request({

        url: `/article/content?id=${id}`,
        // url: `/payment/paymenttype/id/${id}`,

        method: 'get',

    })
}